@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');

body{
  overflow: hidden;
  justify-content: center;
  
}
.App {
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  letter-spacing: 0.25vw;
  color: #474747;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes shake {
   0%, 100% {transform: translateX(0);} 
   10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);} 
   20%, 40%, 60%, 80% {transform: translateX(10px);} 
} 

.scroll::-webkit-scrollbar {
  width: 12px !important;
  border-radius: 0;
  background-color: #f5f5f5 !important;
  cursor: pointer !important;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #474747 !important;
  height: 10px !important;
  position: absolute;
  cursor: pointer !important;
}

.noScroll::-webkit-scrollbar {
  display: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: rgba(0,0,0,.125) !important;
  background-color: white !important;
  color: black !important;
}

.accordion-button:not(.collapsed){
  background-color: white !important;
  color: black !important;
}

.accordion-button:not(.collapsed)::after {
  color: black !important;
}

.accordion-button:after {
  display: none !important;
 }

